<template>
  <div>
    <cta-banner :title="$route.name"/>
    <section class="container mx-auto pb-28" v-if="memorias.length > 0">
      <cta-description class="md:pt-28" :descripcion="informacion" :patrocinar="true" />
      <cta-table :memorias="memorias" />
    </section>
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaTable from '../../components/CtaTable.vue';
import CtaDescription from '../../components/CtaDescription.vue';
import API from "@/api";
import {mapState} from "vuex";

export default {
  name: "Memorias",
  components: {
    CtaBanner,
    CtaTable,
    CtaDescription
  },
  data() {
    return {
      memorias: [],
      informacion: {}
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  created() {
    this.getMemorias();
    this.getInfo();
  },
  methods: {
    async getMemorias() {
      const { data } = await API.getMemorias({ evento_fk: this.idEventoActual });
      this.memorias = data.results;
    },
    async getInfo() {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.informacion = (items[i].codigo === 'AU02') ? items[i] : this.informacion;
      }
    }
  },
}
</script>

<style scoped>

</style>
